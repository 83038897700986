import { template as template_57be059b74814e1b83dea5b6f0a8df56 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_57be059b74814e1b83dea5b6f0a8df56(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
